import { setUserProperties as setUserPropertiesNative } from "firebase/analytics";

import { firebaseAnalytics } from "./firebase-analytics";
import { setDefaultProperties } from "./set-default-properties";

/**
 * https://firebase.google.com/docs/analytics/user-properties?platform=web
 */
export function setUserProperties(properties: Record<string, string>) {
  if (!firebaseAnalytics) {
    return;
  }

  setUserPropertiesNative(firebaseAnalytics, properties);

  setDefaultProperties(properties, "user");
}
