import type { Nullable } from "@sunrise/utils";

import type { FirebasePropertyType } from "./default-properties";
import { updateDefaultProperties } from "./default-properties";

/**
 */
export function setDefaultProperties(
  properties: Record<string, Nullable<string>>,
  scope: FirebasePropertyType = "default",
) {
  // This is here because we need to apparently send the properties for every event manually.
  updateDefaultProperties(properties, scope);
}
