import { getAnalytics } from "firebase/analytics";

import { firebaseApp } from "./firebase-app";

export const firebaseAnalytics =
  process.env["VITE_FIREBASE_PROJECT_ID"] &&
  process.env["VITE_FIREBASE_API_KEY"] &&
  process.env["VITE_FIREBASE_MEASUREMENT_ID"]
    ? getAnalytics(firebaseApp)
    : null;
