import { getAnalytics } from "firebase/analytics";

import { isNil } from "@sunrise/utils";

export type FirebasePropertyType = "default" | "user";

const PROPERTY_MAP: Record<FirebasePropertyType, Map<string, unknown>> = {
  default: new Map(),
  user: new Map(),
};

export function updateDefaultProperties(
  properties: Record<string, unknown>,
  scope: FirebasePropertyType,
) {
  updateMap(PROPERTY_MAP[scope], properties);

  // We are setting the non-user scoped properties also on the user scope.
  // But a bit more manually.
  if ("gtag" in window && typeof window.gtag === "function") {
    const measurementId = getAnalytics()?.app.options.measurementId;
    if (!measurementId) {
      return;
    }

    try {
      window.gtag("config", measurementId, {
        user_properties: {
          ...getDefaultProperties("user", ""),
          ...getDefaultProperties("default", "default_"),
        },
      });
    } catch {
      // If the gtag function is not available, we don't need to do anything.
    }
  }
}

function updateMap(
  map: Map<string, unknown>,
  properties: Record<string, unknown>,
) {
  Object.entries(properties).forEach(([key, value]) => {
    if (isNil(value) || value === "") {
      map.delete(key);
    } else {
      map.set(key, value);
    }
  });
}

export function getDefaultProperties(
  scope: FirebasePropertyType,
  prefix: string = "",
) {
  return Object.fromEntries(
    Array.from(PROPERTY_MAP[scope].entries()).map(([key, value]) => [
      prefix + key,
      value,
    ]),
  );
}
