import { logEvent as logEventFn } from "firebase/analytics";

import { getDefaultProperties } from "./default-properties";
import { firebaseAnalytics } from "./firebase-analytics";

export const logEvent = (event: string, params: Record<string, unknown>) => {
  if (!firebaseAnalytics) {
    return;
  }

  logEventFn(firebaseAnalytics, event, {
    // Manually log the default properties with a prefix per scope so that they are not overridden by the event parameters.
    ...getDefaultProperties("default", "dp_"),
    ...getDefaultProperties("user", "up_"),
    ...params,
  });
};
