import { atomEffect } from "jotai-effect";

import { setDefaultProperties } from "@sunrise/firebase";
import { currentLanguageAtom } from "@sunrise/i18n";

/**
 * Effect that emits analytics events when the app language changes.
 *
 * This is used to set the language in the analytics events.
 * This is emitted as a default property. So it is known for all future events.
 */
export const emitAppLanguageChangeEffect = atomEffect((get) => {
  const currentLanguage = get(currentLanguageAtom);

  setDefaultProperties(
    {
      app_language: currentLanguage,
    },
    "default",
  );
});
